// Copyright © 2023 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'
import { useSelector } from 'react-redux'

import Button from '@ttn-lw/components/button'

import { selectNocConfigEnabled, selectNocConfigUrl, selectNocUrl } from '@ttn-lw/lib/selectors/env'
import sharedMessages from '@ttn-lw/lib/shared-messages'

import { selectSubscriptionProduct } from '@console/store/selectors/subscription-management.tti'

import SubscriptionInfo from './subscription-info.tti'
import NetworkStats from './network-stats/index.tti'
import RegistryTotals from './registry-totals'
import { REGULAR_CLOUD_PLANS } from './utils.tti'

// The noc config coming from `--console.ui.noc.enabled`
const nocConfigUrl = selectNocConfigUrl()
// The noc config coming from `--console.ui.noc.base_url`
const nocEnabled = selectNocConfigEnabled()

const nocUrl = selectNocUrl()

const NetworkInformationContainer = () => {
  const isNocEnabled = nocEnabled && nocConfigUrl !== ''
  const subscriptionInfo = useSelector(selectSubscriptionProduct)
  const isDiscovery = subscriptionInfo && subscriptionInfo.product.symbol === 'DISCOVERY'
  const isAlternativeProductSymbol =
    subscriptionInfo && !REGULAR_CLOUD_PLANS.includes(subscriptionInfo.product.symbol)

  return (
    <>
      {subscriptionInfo && (
        <>
          <SubscriptionInfo />
          {!isAlternativeProductSymbol && <NetworkStats />}
        </>
      )}
      {!isDiscovery && <RegistryTotals />}
      {isNocEnabled && (
        <Button.AnchorLink
          secondary
          href={nocUrl}
          message={sharedMessages.openNoc}
          target="_blank"
          external
        />
      )}
    </>
  )
}

export default NetworkInformationContainer
