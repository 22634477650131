// Copyright © 2024 The Things Industries B.V.

import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Tabs from '@ttn-lw/components/tabs'
import Notification from '@ttn-lw/components/notification'

import Message from '@ttn-lw/lib/components/message'
import GenericNotFound from '@ttn-lw/lib/components/full-view-error/not-found'

import AlertReceivers from '@console/containers/alert-receivers-form/index.tti'
import AlertProfiles from '@console/containers/alert-profiles-form/index.tti'

import sharedMessages from '@ttn-lw/lib/shared-messages'

import { selectAlertProfilesStore } from '@console/store/selectors/alert-notification.tti'

const BASE_PATH = '/admin-panel/alerting-settings'
const ALERT_RECEIVERS = 'alert-receivers'
const ALERT_PROFILES = 'alert-profiles'

const AlertingSettingsContainer = () => {
  const alertProfiles = useSelector(selectAlertProfilesStore)

  const tabs = [
    {
      title: sharedMessages.alertReceivers,
      name: ALERT_RECEIVERS,
      link: `${BASE_PATH}`,
    },
    {
      title: sharedMessages.alertProfiles,
      name: ALERT_PROFILES,
      link: `${BASE_PATH}/${ALERT_PROFILES}`,
    },
  ]

  useEffect(() => {
    document.querySelector('main').scrollTo(0, 0)
  }, [])

  return (
    <>
      <Message
        component="p"
        content={sharedMessages.alertingSettingsText}
        values={{ strong: txt => <strong>{txt}</strong> }}
        className="m-0"
      />
      {!Object.keys(alertProfiles).length && (
        <Notification content={sharedMessages.noAlertProfiles} className="mt-ls-xs" info small />
      )}
      <Tabs tabs={tabs} divider className="mt-ls-xs mb-ls-m" />
      <Routes>
        <Route index Component={AlertReceivers} />
        <Route path="alert-profiles" Component={AlertProfiles} />
        <Route path="*" Component={<GenericNotFound />} />
      </Routes>
    </>
  )
}

export default AlertingSettingsContainer
