// Copyright © 2021 The Things Industries B.V.

import React, { useState, useCallback } from 'react'
import { defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'

import AzureIoTHubImage from '@assets/misc/azure-iot-hub-logo.png'
import tts from '@console/api/tts'
import AZURE_IOT_HUB_INTEGRATION from '@console/constants/azure-iot-hub-integration.tti'
import AZURE_IOT_CENTRAL_INTEGRATION from '@console/constants/azure-iot-central-integration.tti'

import PageTitle from '@ttn-lw/components/page-title'
import Breadcrumb from '@ttn-lw/components/breadcrumbs/breadcrumb'
import { useBreadcrumbs } from '@ttn-lw/components/breadcrumbs/context'
import DataSheet from '@ttn-lw/components/data-sheet'
import Button from '@ttn-lw/components/button'
import Link from '@ttn-lw/components/link'
import Collapse from '@ttn-lw/components/collapse'

import ErrorView from '@ttn-lw/lib/components/error-view'
import Message from '@ttn-lw/lib/components/message'
import RequireRequest from '@ttn-lw/lib/components/require-request'

import AzureIoTHubIntegrationForm from '@console/containers/azure-iot-integration/hub-integration-form/index.tti'
import AzureIoTCentralIntegrationForm from '@console/containers/azure-iot-integration/central-integration-form/index.tti'

import Require from '@console/lib/components/require'

import SubViewError from '@console/views/sub-view-error'

import sharedMessages from '@ttn-lw/lib/shared-messages'
import { selectAsConfig } from '@ttn-lw/lib/selectors/env'

import { mayViewOrEditApplicationPackages } from '@console/lib/feature-checks'

import { getAppPkgDefaultAssoc } from '@console/store/actions/application-packages'

import { selectSelectedApplicationId } from '@console/store/selectors/applications'

import style from './application-integrations-azure-iot.tti.styl'

const m = defineMessages({
  clusterAddress: 'Cluster address',
  credentials: 'Credentials',
  dataExportAddress: 'Data Export address',
  dataExportHeaderKey: 'Data Export header key',
  dataExportHeaderValue: 'Data Export header value',
  generateApiKey: 'Generate API Key',
  infoText:
    'The Azure Internet of Things (IoT) is a collection of Microsoft-managed cloud services that connect, monitor, and control billions of IoT assets. You can setup our Azure IoT integrations below.',
  iotCentralInfo:
    'The Azure IoT Central integration supports streaming data and device twins. This integration connects to an Azure IoT Central application running in your Azure account.',
  iotCentralIntegration: 'Azure IoT Central Integration',
  iotHubDeploymentGuide: 'Azure IoT Hub Deployment Guide',
  iotHubInfo:
    'The Azure IoT Hub integration supports streaming data, creating end devices and device twins. This integration comes with an Azure Resource Manager template to deploy in your Azure account.',
  iotHubIntegration: 'Azure IoT Hub Integration',
})

const AzureIoTIntegration = () => {
  const appId = useSelector(selectSelectedApplicationId)

  useBreadcrumbs(
    'apps.single.integrations.azure-iot',
    <Breadcrumb
      path={`/applications/${appId}/integrations/azure-iot`}
      content={sharedMessages.azureIoT}
    />,
  )

  const asConfig = useSelector(selectAsConfig)

  const createAPIKeyGenerator = description => async () => {
    const key = {
      name: `azure-iot-${description.name}-${Date.now()}`,
      rights: description.rights,
    }
    const result = await tts.Applications.ApiKeys.create(appId, key)

    description.set(result)
  }

  const [iotHubKey, setIoTHubKey] = useState(undefined)
  const handleGenerateIoTHubAPIKey = useCallback(
    createAPIKeyGenerator({
      name: 'hub',
      rights: [
        'RIGHT_APPLICATION_DEVICES_READ',
        'RIGHT_APPLICATION_DEVICES_WRITE',
        'RIGHT_APPLICATION_DEVICES_READ_KEYS',
        'RIGHT_APPLICATION_DEVICES_WRITE_KEYS',
        'RIGHT_APPLICATION_TRAFFIC_DOWN_WRITE',
      ],
      set: setIoTHubKey,
    }),
    [appId],
  )
  const iotHubConnectionData = [
    {
      header: '',
      items: [
        {
          key: sharedMessages.appId,
          type: 'code',
          sensitive: false,
          value: appId,
        },
        {
          key: m.clusterAddress,
          type: 'code',
          sensitive: false,
          value: asConfig.base_url,
        },
      ],
    },
  ]
  if (iotHubKey) {
    iotHubConnectionData[0].items.push({
      key: sharedMessages.apiKey,
      type: 'code',
      value: iotHubKey.key,
    })
  } else {
    iotHubConnectionData[0].items.push({
      key: sharedMessages.apiKey,
      value: (
        <React.Fragment>
          <Button
            className="mr-cs-s"
            message={m.generateApiKey}
            onClick={handleGenerateIoTHubAPIKey}
          />
          <Link to={`/applications/${appId}/api-keys`} secondary>
            <Message content={sharedMessages.goToApiKeys} />
          </Link>
        </React.Fragment>
      ),
    })
  }

  const [iotCentralKey, setIoTCentralKey] = useState(undefined)
  const handleGenerateIoTCentralAPIKey = useCallback(
    createAPIKeyGenerator({
      name: 'central',
      rights: ['RIGHT_APPLICATION_TRAFFIC_DOWN_WRITE'],
      set: setIoTCentralKey,
    }),
    [appId],
  )
  const iotCentralConnectionData = [
    {
      header: '',
      items: [
        {
          key: m.dataExportAddress,
          type: 'code',
          sensitive: false,
          value: `${asConfig.base_url}/as/applications/${appId}/packages/azureiotcentral/events`,
        },
        {
          key: m.dataExportHeaderKey,
          type: 'code',
          sensitive: false,
          value: 'Authorization',
        },
      ],
    },
  ]
  if (iotCentralKey) {
    iotCentralConnectionData[0].items.push({
      key: m.dataExportHeaderValue,
      type: 'code',
      value: `Bearer ${iotCentralKey.key}`,
    })
  } else {
    iotCentralConnectionData[0].items.push({
      key: m.dataExportHeaderValue,
      value: (
        <React.Fragment>
          <Button
            className="mr-cs-s"
            message={m.generateApiKey}
            onClick={handleGenerateIoTCentralAPIKey}
          />
          <Link to={`/applications/${appId}/api-keys`} secondary>
            <Message content={sharedMessages.goToApiKeys} />
          </Link>
        </React.Fragment>
      ),
    })
  }

  const selector = ['data']
  return (
    <Require
      featureCheck={mayViewOrEditApplicationPackages}
      otherwise={{ redirect: `/applications/${appId}` }}
    >
      <RequireRequest
        requestAction={[
          getAppPkgDefaultAssoc(appId, AZURE_IOT_HUB_INTEGRATION.DEFAULT_PORT, selector),
          getAppPkgDefaultAssoc(appId, AZURE_IOT_CENTRAL_INTEGRATION.DEFAULT_PORT, selector),
        ]}
      >
        <ErrorView errorRender={SubViewError}>
          <div className="container container--xxl grid">
            <PageTitle title={sharedMessages.azureIoT} />
            <div className="item-12 lg:item-8">
              <img className={style.logo} src={AzureIoTHubImage} alt={sharedMessages.azureIoTHub} />
              <Message content={m.infoText} className={style.info} />
              <div>
                <Message
                  component="h4"
                  content={sharedMessages.furtherResources}
                  className={style.furtherResources}
                />
                <Link.DocLink path="/integrations/cloud-integrations/azure-iot-hub/" secondary>
                  <Message content={m.iotHubIntegration} />
                </Link.DocLink>
                {' | '}
                <Link.DocLink
                  path="/integrations/cloud-integrations/azure-iot-hub/deployment/"
                  secondary
                >
                  <Message content={m.iotHubDeploymentGuide} />
                </Link.DocLink>
              </div>
              <hr className={style.hRule} />
              <Collapse title={sharedMessages.azureIoTHub} description={m.iotHubInfo}>
                <Message component="h3" content={m.credentials} />
                <DataSheet data={iotHubConnectionData} />
                <hr className={style.hRule} />
                <Message component="h3" content={sharedMessages.configuration} />
                <AzureIoTHubIntegrationForm />
              </Collapse>
              <Collapse title={sharedMessages.azureIoTCentral} description={m.iotCentralInfo}>
                <Message component="h3" content={m.credentials} />
                <DataSheet data={iotCentralConnectionData} />
                <hr className={style.hRule} />
                <Message component="h3" content={sharedMessages.configuration} />
                <AzureIoTCentralIntegrationForm />
              </Collapse>
            </div>
          </div>
        </ErrorView>
      </RequireRequest>
    </Require>
  )
}

export default AzureIoTIntegration
