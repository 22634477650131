// Copyright © 2023 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { defineMessages } from 'react-intl'

import STORAGE_INTEGRATION from '@console/constants/storage-integration'

import Switch from '@ttn-lw/components/switch'
import Status from '@ttn-lw/components/status'
import Link from '@ttn-lw/components/link'
import ErrorNotification from '@ttn-lw/components/error-notification'

import Message from '@ttn-lw/lib/components/message'
import RequireRequest from '@ttn-lw/lib/components/require-request'

import sharedMessages from '@ttn-lw/lib/shared-messages'
import { selectAsConfig } from '@ttn-lw/lib/selectors/env'
import attachPromise from '@ttn-lw/lib/store/actions/attach-promise'

import {
  setAppPkgDefaultAssoc,
  getAppPkgDefaultAssoc,
  deleteAppPkgDefaultAssoc,
} from '@console/store/actions/application-packages'

import { selectApplicationPackageDefaultAssociation } from '@console/store/selectors/application-packages'
import { selectSelectedApplicationId } from '@console/store/selectors/applications'

import style from './storage-integration-panel.styl'

const m = defineMessages({
  isActivated: 'The Storage Integration is currently activated',
  isDeactivated: 'The Storage Integration is currently deactivated',
  activate: 'Activate Storage Integration',
  deactivate: 'Deactivate Storage Integration',
  apiInfo:
    'You can use the endpoints below to retrieve data from the storage. For detailed API description, see <DocLink>Storage Integration API</DocLink>.',
})

const StorageIntegrationPanel = () => {
  const appId = useSelector(selectSelectedApplicationId)
  const [fetching, setFetching] = useState(false)
  const currentPackage = useSelector(state =>
    selectApplicationPackageDefaultAssociation(state, STORAGE_INTEGRATION.DEFAULT_PORT),
  )
  const [error, setError] = useState(undefined)
  const dispatch = useDispatch()
  const asUri = selectAsConfig().base_url
  const isActivated = Boolean(currentPackage)

  const handleActivateClick = useCallback(async () => {
    try {
      setError(undefined)
      await dispatch(
        attachPromise(
          setAppPkgDefaultAssoc(appId, STORAGE_INTEGRATION.DEFAULT_PORT, {
            package_name: STORAGE_INTEGRATION.DEFAULT_PACKAGE_NAME,
          }),
        ),
      )
    } catch (error) {
      setError(error)
    } finally {
      setFetching(false)
    }
  }, [dispatch, appId, setError])

  const handleDeactivateClick = useCallback(async () => {
    try {
      setError(undefined)
      await dispatch(
        attachPromise(deleteAppPkgDefaultAssoc(appId, STORAGE_INTEGRATION.DEFAULT_PORT)),
      )
    } catch (error) {
      setError(error)
    } finally {
      setFetching(false)
    }
  }, [dispatch, appId, setError])

  return (
    <RequireRequest
      requestAction={getAppPkgDefaultAssoc(appId, STORAGE_INTEGRATION.DEFAULT_PORT, ['data'])}
    >
      <Message content={sharedMessages.status} component="h3" />
      {!isActivated ? (
        <Status
          status="bad"
          label={m.isDeactivated}
          flipped
          className={style.status}
          disabled={fetching}
        />
      ) : (
        <Status
          status="good"
          label={m.isActivated}
          flipped
          className={style.status}
          disabled={fetching}
        />
      )}
      <label className="d-flex al-center">
        <Message content={m.activate} component="span" className="mr-cs-xs" />
        <Switch
          onChange={isActivated ? handleDeactivateClick : handleActivateClick}
          checked={isActivated}
          disabled={fetching}
        />
      </label>
      {error && <ErrorNotification error={error} small />}
      {isActivated && (
        <>
          <Message
            content={m.apiInfo}
            component="p"
            values={{
              DocLink: msg => (
                <Link.DocLink path="/reference/api/storage_integration" secondary>
                  {msg}
                </Link.DocLink>
              ),
            }}
          />
          <div className={style.endpoints}>
            <code>GET</code>{' '}
            <code>{`${asUri}/as/applications/${appId}/packages/storage/{type}`}</code>
            <br />
            <code>GET</code>{' '}
            <code>{`${asUri}/as/applications/${appId}/devices/{device_id}/packages/storage/{type}`}</code>
          </div>
        </>
      )}
    </RequireRequest>
  )
}

export default StorageIntegrationPanel
