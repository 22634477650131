// Copyright © 2024 The Things Industries B.V.

import React from 'react'
import { defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'

import Panel, { PanelError } from '@ttn-lw/components/panel'
import NewsItem from '@ttn-lw/components/news-panel/news-item'
import { IconNews } from '@ttn-lw/components/icon'

import RequireRequest from '@ttn-lw/lib/components/require-request'
import Message from '@ttn-lw/lib/components/message'

import {
  selectPluginTTSCloudEnabled,
  selectPluginTTSCloudNewsApi,
  selectPluginTTSSandboxNewsApi,
} from '@ttn-lw/lib/selectors/env'

import { getNewsList } from '@console/store/actions/news.tti'

import { selectNewsItemsStore } from '@console/store/selectors/news.tti'

const getWebsiteBaseUrl = () => {
  if (selectPluginTTSCloudEnabled()) {
    return selectPluginTTSCloudNewsApi()?.replace('/api', '')
  }

  return selectPluginTTSSandboxNewsApi()?.replace('/api', '')
}

/**
 * If tts-cloud plugin is enabled the news url is /news and the details page is on /news/{slug}.
 * If tts-sandbox plugin is enabled the articles url is on /articles but the details page is on /article/{slug},
 * so the last letter needs to be cut out of the base url. Can't be changed on ttn.org side because a lot of links are going to become inactive.
 * @param {string} slug - The unique slug of the article.
 * @returns {string} The article details link.
 */
const getArticleUrl = slug => {
  const baseUrl = getWebsiteBaseUrl()
  if (selectPluginTTSCloudEnabled()) {
    return `${baseUrl}/${slug}`
  }

  return `${baseUrl?.slice(0, -1)}/${slug}`
}

const m = defineMessages({
  latestNews: 'Latest news',
  blog: 'Blog',
  newsUnavailable: 'News are currently unavailable',
})
const LatestNewsPanel = () => {
  const news = useSelector(selectNewsItemsStore)

  return (
    <Panel
      title={m.latestNews}
      icon={IconNews}
      shortCutLinkPath={getWebsiteBaseUrl()}
      shortCutLinkTitle={m.blog}
      shortCutLinkTarget="_blank"
      divider
      className="h-full"
      compact
    >
      <RequireRequest
        spinnerProps={{ inline: true, center: true }}
        errorRenderFunction={() => (
          <PanelError>
            <Message
              component="p"
              content={m.newsUnavailable}
              className="c-text-neutral-light text-center"
            />
          </PanelError>
        )}
        requestAction={getNewsList()}
      >
        <div className="d-flex direction-column gap-cs-xs">
          {news.map(n => (
            <NewsItem
              key={n.id}
              title={n.title}
              publishDate={n.pub_date}
              image={n.image}
              slug={n.slug}
              path={getArticleUrl(n.slug)}
            />
          ))}
        </div>
      </RequireRequest>
    </Panel>
  )
}

export default LatestNewsPanel
