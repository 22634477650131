// Copyright © 2024 The Things Industries B.V.

import { random } from 'lodash'

const strokeColor = getComputedStyle(document.documentElement).getPropertyValue(
  '--c-bg-brand-normal',
)

const borderColor = getComputedStyle(document.documentElement).getPropertyValue(
  '--c-border-neutral-light',
)

export const options = {
  chart: {
    type: 'area',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    events: {
      mounted: chart => {
        chart.windowResizeHandler()
      },
    },
  },
  colors: [strokeColor],
  xaxis: {
    type: 'datetime',
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 2,
    colors: [strokeColor],
  },
  tooltip: {
    x: {
      show: false,
    },
    marker: {
      show: false,
    },
    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      const val = series[seriesIndex][dataPointIndex]
      const date = new Date(w.globals.seriesX[seriesIndex][dataPointIndex])
      const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })

      return `<div class="pl-cs-s pr-cs-s pt-cs-xs pb-cs-xs"><strong>${formattedDate}</strong>: ${val}</div>`
    },
  },
  zoom: {
    enabled: false,
  },
  selection: {
    enabled: false,
  },
  pan: {
    enabled: false,
  },
  grid: {
    borderColor,
    show: false,
    strokeDashArray: 0,
    padding: {
      left: -10,
      right: 0,
      bottom: -13,
      top: -20,
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [5.67, 100],
      colorStops: [
        {
          offset: 0,
          color: '#0D83D0',
          opacity: 0.22,
        },
        {
          offset: 100,
          color: '#0D83D0',
          opacity: 0.03,
        },
      ],
    },
  },
}

export const getSeries = (metrics, days) => {
  if (!metrics.length) return []

  const data = metrics
    .slice(-days - 1)
    .map(m => ({ x: new Date(m.time).getTime(), y: m.count ?? 0 }))

  return [
    {
      data,
    },
  ]
}

export const getRandomSeries = maxNumber => {
  const items = []
  const today = new Date()

  for (let i = 0; i < 31; i++) {
    // Clone today's date
    const date = new Date(today)

    // Subtract i days from today
    date.setDate(date.getDate() - i)

    // Format the date as an ISO string
    const time = date.toISOString()

    // Get minimum number based on percentage
    const minNumber = maxNumber - Math.round(maxNumber * 0.3)

    // Generate a random count between minNumber and maxNumber
    const count = random(minNumber, maxNumber)

    // Push the generated object to the items array
    items.unshift({
      time,
      count,
    })
  }

  return items
}
