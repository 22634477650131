// Copyright © 2024 The Things Industries B.V.

import { GET_NEWS_LIST_SUCCESS } from '@console/store/actions/news.tti'

const defaultState = {
  items: [],
}

const news = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_NEWS_LIST_SUCCESS:
      return {
        ...state,
        items: payload.entities,
      }
    default:
      return state
  }
}

export default news
