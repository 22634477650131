// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'

import Link from '@ttn-lw/components/link'

import DateTime from '@ttn-lw/lib/components/date-time'
import Message from '@ttn-lw/lib/components/message'

import PropTypes from '@ttn-lw/lib/prop-types'

import styles from './news-item.styl'

const NewsItem = ({ title, image, publishDate, slug, path }) => (
  <Link to={path} target="_blank" className={styles.item}>
    <div className={styles.imageContainer}>
      <img src={image} alt={slug} className={styles.image} />
    </div>
    <div className="d-flex direction-column j-center">
      <Message content={title} className={styles.title} />
      <DateTime
        value={publishDate}
        time={false}
        dateFormatOptions={{
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        }}
        className="c-text-neutral-light fs-s"
      />
    </div>
  </Link>
)

NewsItem.propTypes = {
  image: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  publishDate: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.message.isRequired,
}

export default NewsItem
