// Copyright © 2023 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import createRequestActions from '@ttn-lw/lib/store/actions/create-request-actions'

const PARSE_END_DEVICE_QR_CODE_BASE = 'PARSE_END_DEVICE_QR_CODE'
export const [
  {
    request: PARSE_END_DEVICE_QR_CODE,
    success: PARSE_END_DEVICE_QR_CODE_SUCCESS,
    failure: PARSE_END_DEVICE_QR_CODE_FAILURE,
  },
  {
    request: parseEndDeviceQRCode,
    success: parseEndDeviceQRCodeSuccess,
    failure: parseEndDeviceQRCodeFailure,
  },
] = createRequestActions(PARSE_END_DEVICE_QR_CODE_BASE, qrCode => ({ qrCode }))

const PARSE_GATEWAY_QR_CODE_BASE = 'PARSE_GATEWAY_QR_CODE'
export const [
  {
    request: PARSE_GATEWAY_QR_CODE,
    success: PARSE_GATEWAY_QR_CODE_SUCCESS,
    failure: PARSE_GATEWAY_QR_CODE_FAILURE,
  },
  {
    request: parseGatewayQRCode,
    success: parseGatewayQRCodeSuccess,
    failure: parseGatewayQRCodeFailure,
  },
] = createRequestActions(PARSE_GATEWAY_QR_CODE_BASE, qrCode => ({ qrCode }))
