// Copyright © 2024 The Things Industries B.V.

import axios from 'axios'

import createRequestLogic from '@ttn-lw/lib/store/logics/create-request-logic'
import {
  selectPluginTTSCloudEnabled,
  selectPluginTTSCloudNewsApi,
  selectPluginTTSSandboxEnabled,
  selectPluginTTSSandboxNewsApi,
} from '@ttn-lw/lib/selectors/env'

import * as news from '@console/store/actions/news.tti'

// News

const getNewsLogic = createRequestLogic({
  type: news.GET_NEWS_LIST,
  process: async () => {
    let url

    if (selectPluginTTSCloudEnabled()) {
      url = selectPluginTTSCloudNewsApi()
    } else if (selectPluginTTSSandboxEnabled()) {
      url = selectPluginTTSSandboxNewsApi()
    }
    if (url) {
      try {
        const response = await axios.get(url, {
          params: {
            page_size: 3,
          },
        })

        return { entities: response.data.results }
      } catch (error) {
        // Do not follow through on errors and just return an empty array.
        return { entities: [] }
      }
    }
    return { entities: [] }
  },
})

export default [getNewsLogic]
