// Copyright © 2022 The Things Industries B.V.

import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { isEmpty, merge } from 'lodash'
import classNames from 'classnames'

import Form from '@ttn-lw/components/form'
import Input from '@ttn-lw/components/input'
import UnitInput from '@ttn-lw/components/unit-input'
import Checkbox from '@ttn-lw/components/checkbox'
import SubmitBar from '@ttn-lw/components/submit-bar'
import SubmitButton from '@ttn-lw/components/submit-button'
import KeyValueMap from '@ttn-lw/components/key-value-map'
import toast from '@ttn-lw/components/toast'
import Link from '@ttn-lw/components/link'

import Message from '@ttn-lw/lib/components/message'

import diff from '@ttn-lw/lib/diff'
import sharedMessages from '@ttn-lw/lib/shared-messages'
import tooltipIds from '@ttn-lw/lib/constants/tooltip-ids'
import attachPromise from '@ttn-lw/lib/store/actions/attach-promise'
import PropTypes from '@ttn-lw/lib/prop-types'

import { updateOwnTenant } from '@console/store/actions/tenant.tti'

import { selectOwnTenant } from '@console/store/selectors/tenant.tti'
import { selectIsConfiguration } from '@console/store/selectors/identity-server'

import validationSchema from './validation-schema.tti'
import m from './messages.tti'

import style from './tenant-settings-form.tti.styl'

const initialValues = {
  is: {
    admin_rights: {
      all: true,
    },
    end_device_picture: {
      disable_upload: false,
    },
    profile_picture: {
      disable_upload: false,
      use_gravatar: true,
    },
    user_registration: {
      admin_approval: {
        required: true,
      },
      contact_info_validation: {
        required: true,
      },
      enabled: true,
      invitation: {
        required: true,
        token_ttl: '604800s',
      },
      password_requirements: {
        max_length: 1000,
        min_digits: 1,
        min_special: 1,
        min_length: 8,
        min_uppercase: 1,
      },
    },
    user_rights: {
      create_applications: true,
      create_organizations: true,
      create_gateways: true,
      create_clients: true,
    },
  },
  ns: {
    cooldown_window: '1s',
    deduplication_window: '200ms',
    dev_addr_prefixes: [],
  },
  ui: {
    branding_base_url: '',
  },
}

const nullInputEncode = val => (val === '' ? null : { branding_base_url: val })
const nullInputDecode = val =>
  val !== null && val.branding_base_url !== '' ? val.branding_base_url : ''

const TenantSettingsFormFields = ({ hasClusterOverrides, hasAdminRestriction }) => {
  const {
    values: {
      _use_user_registration_defaults,
      _use_password_requirements_defaults,
      _use_user_rights_defaults,
      _use_uploads_defaults,
      _use_network_settings_defaults,
    },
  } = useFormikContext()

  const disableDevAddrPrefixes =
    _use_network_settings_defaults || hasClusterOverrides || hasAdminRestriction

  const readOnly = useCallback(
    () => hasClusterOverrides || hasAdminRestriction,
    [hasClusterOverrides, hasAdminRestriction],
  )

  return (
    <div>
      <div className="grid" data-test-id="tenant-form-section">
        <div className={classNames(style.sectionHead, 'item-5 md:item-3')}>
          <Message content={m.userRegistration} component="h3" className="mt-0" />
          <Message content={m.userRegistrationDesc} component="p" className="mt-cs-s" />
        </div>
        <div className="item-7 md:item-9">
          <Form.Field
            name="_use_user_registration_defaults"
            component={Checkbox}
            label={m.useDefaultSettings}
            description={m.useDefaultSettingsDesc}
          />
          <Form.Field
            name="is.user_registration.enabled"
            component={Checkbox}
            label={m.userRegistrationEnabled}
            disabled={_use_user_registration_defaults}
            description={m.userRegistrationEnabledDescription}
          />
          <Form.Field
            name="is.user_registration.admin_approval.required"
            component={Checkbox}
            label={m.userRegistrationAdminApproval}
            disabled={_use_user_registration_defaults}
            description={m.userRegistrationAdminApprovalDesc}
          />
          <Form.Field
            name="is.user_registration.contact_info_validation.required"
            component={Checkbox}
            label={m.userRegistrationContactInfoValidationRequired}
            disabled={_use_user_registration_defaults}
            description={m.userRegistrationContactInfoValidationDesc}
          />
          <Form.Field
            name="is.user_registration.invitation.required"
            component={Checkbox}
            label={m.userRegistrationInvitationRequired}
            disabled={_use_user_registration_defaults}
            description={m.userRegistrationInvitationDesc}
          />
          <Form.Field
            title={m.userRegistrationInvitationTokenTTL}
            name="is.user_registration.invitation.token_ttl"
            component={UnitInput.Duration}
            inputWidth="s"
            unitSelector={['s', 'm', 'h']}
            disabled={_use_user_registration_defaults}
            required={!_use_user_registration_defaults}
            description={m.invitationTokenDesc}
          />
        </div>
      </div>
      <div className={classNames(style.section, 'grid')} data-test-id="tenant-form-section">
        <div className={classNames(style.sectionHead, 'item-5 md:item-3')}>
          <Message content={m.passwordRequirements} component="h3" className="mt-0" />
          <Message content={m.passwordRequirementsDesc} component="p" className="mt-cs-s" />
        </div>
        <div className="item-7 md:item-9">
          <Form.Field
            name="_use_password_requirements_defaults"
            component={Checkbox}
            label={m.useDefaultSettings}
            description={m.useDefaultSettingsDesc}
          />
          <Form.FieldContainer horizontal>
            <Form.Field
              name="is.user_registration.password_requirements.min_length"
              component={Input}
              type="number"
              title={m.userRegistrationPasswordMinLength}
              inputWidth="xxs"
              fieldWidth="xxs"
              disabled={_use_password_requirements_defaults}
              required={!_use_password_requirements_defaults}
            />
            <Form.Field
              name="is.user_registration.password_requirements.max_length"
              component={Input}
              type="number"
              title={m.userRegistrationPasswordMaxLength}
              inputWidth="xxs"
              fieldWidth="xxs"
              disabled={_use_password_requirements_defaults}
              required={!_use_password_requirements_defaults}
            />
          </Form.FieldContainer>
          <Form.FieldContainer horizontal>
            <Form.Field
              name="is.user_registration.password_requirements.min_digits"
              component={Input}
              type="number"
              title={m.userRegistrationPasswordMinDigits}
              inputWidth="xxs"
              fieldWidth="xxs"
              disabled={_use_password_requirements_defaults}
              required={!_use_password_requirements_defaults}
            />
            <Form.Field
              name="is.user_registration.password_requirements.min_special"
              component={Input}
              type="number"
              title={m.userRegistrationPasswordMinSpecial}
              inputWidth="xxs"
              fieldWidth="xxs"
              disabled={_use_password_requirements_defaults}
              required={!_use_password_requirements_defaults}
            />
            <Form.Field
              name="is.user_registration.password_requirements.min_uppercase"
              component={Input}
              type="number"
              title={m.userRegistrationPasswordMinUppercase}
              inputWidth="xxs"
              fieldWidth="xxs"
              disabled={_use_password_requirements_defaults}
              required={!_use_password_requirements_defaults}
            />
          </Form.FieldContainer>
        </div>
      </div>
      <div className={classNames(style.section, 'grid')} data-test-id="tenant-form-section">
        <div className={classNames(style.sectionHead, 'item-5 md:item-3')}>
          <Message content={m.userRights} component="h3" className="mt-0" />
          <Message content={m.userRightsDesc} component="p" className="mt-cs-s" />
        </div>
        <div className="item-7 md:item-9">
          <Form.Field
            name="_use_user_rights_defaults"
            component={Checkbox}
            label={m.useDefaultSettings}
            description={m.useDefaultSettingsDesc}
          />
          <Form.Field
            name="is.admin_rights.all"
            component={Checkbox}
            label={m.adminRightsAll}
            disabled={_use_user_rights_defaults}
            description={m.adminRightsAllDesc}
          />
          <Form.Field
            name="is.user_rights.create_applications"
            component={Checkbox}
            label={m.userRightsCreateApplications}
            disabled={_use_user_rights_defaults}
          />
          <Form.Field
            name="is.user_rights.create_organizations"
            component={Checkbox}
            label={m.userRightsCreateOrganizations}
            disabled={_use_user_rights_defaults}
          />
          <Form.Field
            name="is.user_rights.create_gateways"
            component={Checkbox}
            label={m.userRightsCreateGateways}
            disabled={_use_user_rights_defaults}
          />
          <Form.Field
            name="is.user_rights.create_clients"
            component={Checkbox}
            label={m.userRightsCreateClients}
            disabled={_use_user_rights_defaults}
          />
        </div>
      </div>
      <div className={classNames(style.section, 'grid')} data-test-id="tenant-form-section">
        <div className={classNames(style.sectionHead, 'item-5 md:item-3')}>
          <Message content={m.uploads} component="h3" className="mt-0" />
          <Message content={m.uploadsDesc} component="p" className="mt-cs-s" />
        </div>
        <div className="item-7 md:item-9">
          <Form.Field
            name="_use_uploads_defaults"
            component={Checkbox}
            label={m.useDefaultSettings}
            description={m.useDefaultSettingsDesc}
          />
          <Form.Field
            name="is.end_device_picture.disable_upload"
            component={Checkbox}
            label={m.endDevicePictureDisableUpload}
            disabled={_use_uploads_defaults}
            description={m.profilePictureDisableUploadDesc}
          />
          <Form.Field
            name="is.profile_picture.disable_upload"
            component={Checkbox}
            label={m.profilePictureDisableUpload}
            disabled={_use_uploads_defaults}
          />
          <Form.Field
            name="is.profile_picture.use_gravatar"
            component={Checkbox}
            label={m.profilePictureUseGravatar}
            disabled={_use_uploads_defaults}
            description={{
              ...m.profilePictureUseGravatarDesc,
              values: {
                Link: val => (
                  <Link.Anchor href="https://gravatar.com/" secondary external>
                    {val}
                  </Link.Anchor>
                ),
              },
            }}
          />
        </div>
      </div>
      <div className={classNames(style.section, 'grid')} data-test-id="tenant-form-section">
        <div className={classNames(style.sectionHead, 'item-5 md:item-3')}>
          <Message content={sharedMessages.networkSettings} component="h3" className="mt-0" />
          <Message content={m.networkSettingsDesc} component="p" className="mt-cs-s" />
        </div>
        <div className="item-7 md:item-9">
          <Form.Field
            name="_use_network_settings_defaults"
            component={Checkbox}
            label={m.useDefaultSettings}
            description={m.useDefaultSettingsDesc}
          />
          <Form.Field
            name="ns.deduplication_window"
            title={m.deduplicationWindow}
            component={UnitInput.Duration}
            inputWidth="s"
            unitSelector={['ms', 's']}
            disabled={_use_network_settings_defaults}
            required={!_use_network_settings_defaults}
            tooltipId={tooltipIds.DEDUPLICATION_WINDOW}
          />
          <Form.Field
            name="ns.cooldown_window"
            title={m.cooldownWindow}
            component={UnitInput.Duration}
            inputWidth="s"
            unitSelector={['ms', 's']}
            disabled={_use_network_settings_defaults}
            required={!_use_network_settings_defaults}
            tooltipId={tooltipIds.COOLDOWN_WINDOW}
          />
          <Form.Field
            indexAsKey
            name="ns.dev_addr_prefixes"
            component={KeyValueMap}
            title={m.devAddrPrefixes}
            addMessage={m.addPrefix}
            valuePlaceholder={m.addPrefixPlaceholder}
            disabled={disableDevAddrPrefixes}
            isReadOnly={readOnly}
            tooltipId={tooltipIds.DEV_ADDRESS_PREFIXES}
          />
        </div>
      </div>
      <div className={classNames(style.section, 'grid')} data-test-id="tenant-form-section">
        <div className={classNames(style.sectionHead, 'item-5 md:item-3')}>
          <Message content={m.branding} component="h3" className="mt-0" />
          <Message content={m.brandingDesc} component="p" className="mt-cs-s" />
        </div>
        <div className="item-7 md:item-9">
          <Form.Field
            name="ui"
            component={Input}
            title={m.brandingBaseUrl}
            encode={nullInputEncode}
            decode={nullInputDecode}
            description={{
              ...m.brandingBaseUrlDesc,
              values: {
                Link: val => (
                  <Link.DocLink secondary path="/reference/branding/">
                    {val}
                  </Link.DocLink>
                ),
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

TenantSettingsFormFields.propTypes = {
  hasAdminRestriction: PropTypes.bool.isRequired,
  hasClusterOverrides: PropTypes.bool.isRequired,
}

const TenantSettingsFormInner = () => {
  const ownTenant = useSelector(selectOwnTenant)
  const tenantId = ownTenant.ids.tenant_id
  const isConfig = useSelector(selectIsConfiguration)
  const dispatch = useDispatch()
  const [error, setError] = useState(undefined)

  const hasConfig = ownTenant && ownTenant.configuration && ownTenant.configuration.default_cluster
  const hasUserRegistrationConfig =
    hasConfig &&
    Boolean(ownTenant.configuration.default_cluster?.is?.user_registration) &&
    !(
      Object.keys(ownTenant.configuration.default_cluster.is.user_registration).length === 1 &&
      Boolean(ownTenant.configuration.default_cluster.is.user_registration?.password_requirements)
    )
  const hasUserPasswordRequirementsConfig =
    hasConfig &&
    Boolean(ownTenant?.configuration.default_cluster?.is?.user_registration?.password_requirements)
  const hasUserRightsConfig =
    hasConfig && Boolean(ownTenant.configuration.default_cluster?.is?.user_rights)
  const hasUploadsConfig =
    hasConfig &&
    Boolean(
      ownTenant.configuration.default_cluster?.is?.profile_picture ||
        ownTenant.configuration.default_cluster?.is?.end_device_picture,
    )
  const hasNetworkSettingsConfig = hasConfig && Boolean(ownTenant.configuration.default_cluster?.ns)
  const hasDevAddrPrefixesConfig =
    !isEmpty(ownTenant.configuration?.default_cluster?.ns) &&
    'dev_addr_prefixes' in ownTenant.configuration?.default_cluster?.ns &&
    !isEmpty(ownTenant.configuration.default_cluster?.ns?.dev_addr_prefixes)
  const hasClusterOverrides =
    ownTenant && ownTenant.configuration && Boolean(ownTenant.configuration?.clusters)
  const hasAdminRestriction = isConfig.admin_restrictions?.managed_fields_updates

  const composedInitialValues = {
    _use_user_registration_defaults: !hasUserRegistrationConfig,
    _use_password_requirements_defaults: !hasUserPasswordRequirementsConfig,
    _use_user_rights_defaults: !hasUserRightsConfig,
    _use_uploads_defaults: !hasUploadsConfig,
    _use_network_settings_defaults: !hasNetworkSettingsConfig,
    ...validationSchema.cast(
      merge(
        {},
        initialValues,
        { is: isConfig },
        {
          ns: {
            ...initialValues.ns,
            ...ownTenant.configuration?.default_cluster?.ns,
            dev_addr_prefixes: hasDevAddrPrefixesConfig
              ? ownTenant.configuration?.default_cluster?.ns.dev_addr_prefixes
              : ownTenant.configuration?.clusters?.[tenantId]?.ns?.dev_addr_prefixes || [],
          },
        },
        hasConfig ? ownTenant.configuration.default_cluster : {},
      ),
      {
        stripUnknown: true,
      },
    ),
  }

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const {
        _use_network_settings_defaults,
        _use_uploads_defaults,
        _use_user_registration_defaults,
        _use_user_rights_defaults,
        _use_password_requirements_defaults,
        ...tenantConfig
      } = validationSchema.cast(values)

      const patch = diff(composedInitialValues, tenantConfig, { patchArraysItems: false })

      try {
        await dispatch(
          attachPromise(updateOwnTenant({ configuration: { default_cluster: patch } })),
        )
        setError(undefined)
        toast({
          title: sharedMessages.success,
          message: m.successMessage,
          type: toast.types.SUCCESS,
        })
      } catch (error) {
        setError(error)
      }
      setSubmitting(false)
    },
    [dispatch, composedInitialValues],
  )

  return (
    <Form
      initialValues={composedInitialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      error={error}
    >
      <TenantSettingsFormFields
        hasClusterOverrides={hasClusterOverrides}
        hasAdminRestriction={hasAdminRestriction}
      />
      <div className="item-12">
        <SubmitBar align="start">
          <Form.Submit
            component={SubmitButton}
            message={sharedMessages.saveChanges}
            className="mr-cs-s"
          />
          <SubmitBar.Message content={m.submitNote} />
        </SubmitBar>
      </div>
    </Form>
  )
}

export default TenantSettingsFormInner
