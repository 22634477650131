// Copyright © 2023 The Things Industries B.V.

import createRequestActions from '@ttn-lw/lib/store/actions/create-request-actions'

export const GET_NOC_CONFIGURATION_BASE = 'GET_NOC_CONFIGURATION'
export const [
  {
    request: GET_NOC_CONFIGURATION,
    success: GET_NOC_CONFIGURATION_SUCCESS,
    failure: GET_NOC_CONFIGURATION_FAILURE,
  },
  {
    request: getNocConfiguration,
    success: getNocConfigurationSuccess,
    failure: getNocConfigurationFailure,
  },
] = createRequestActions(GET_NOC_CONFIGURATION_BASE)

export const [
  {
    request: GET_NOC_CONNECTED_GATEWAYS,
    success: GET_NOC_CONNECTED_GATEWAYS_SUCCESS,
    failure: GET_NOC_CONNECTED_GATEWAYS_FAILURE,
  },
  {
    request: getNocConnectedGateways,
    success: getNocConnectedGatewaysSuccess,
    failure: getNocConnectedGatewaysFailure,
  },
] = createRequestActions('GET_NOC_CONNECTED_GATEWAYS', (from, to, bucketInterval) => ({
  from,
  to,
  bucketInterval,
}))

export const [
  {
    request: GET_NOC_GATEWAY_UPTIME,
    success: GET_NOC_GATEWAY_UPTIME_SUCCESS,
    failure: GET_NOC_GATEWAY_UPTIME_FAILURE,
  },
  {
    request: getNocGatewayUptime,
    success: getNocGatewayUptimeSuccess,
    failure: getNocGatewayUptimeFailure,
  },
] = createRequestActions('GET_NOC_GATEWAY_UPTIME', (gatewayId, from, to) => ({
  gatewayId,
  from,
  to,
}))

export const [
  {
    request: GET_NOC_ACTIVE_APPLICATIONS,
    success: GET_NOC_ACTIVE_APPLICATIONS_SUCCESS,
    failure: GET_NOC_ACTIVE_APPLICATIONS_FAILURE,
  },
  {
    request: getNocActiveApplications,
    success: getNocActiveApplicationsSuccess,
    failure: getNocActiveApplicationsFailure,
  },
] = createRequestActions('GET_NOC_ACTIVE_APPLICATIONS', (from, to, bucketInterval) => ({
  from,
  to,
  bucketInterval,
}))

export const [
  {
    request: GET_NOC_ACTIVE_END_DEVICES,
    success: GET_NOC_ACTIVE_END_DEVICES_SUCCESS,
    failure: GET_NOC_ACTIVE_END_DEVICES_FAILURE,
  },
  {
    request: getNocActiveEndDevices,
    success: getNocActiveEndDevicesSuccess,
    failure: getNocActiveEndDevicesFailure,
  },
] = createRequestActions('GET_NOC_ACTIVE_END_DEVICES', (from, to, bucketInterval) => ({
  from,
  to,
  bucketInterval,
}))

export const [
  {
    request: GET_GATEWAY_PACKETS_PER_DATA_RATE,
    success: GET_GATEWAY_PACKETS_PER_DATA_RATE_SUCCESS,
    failure: GET_GATEWAY_PACKETS_PER_DATA_RATE_FAILURE,
  },
  {
    request: getGatewayPacketsPerDataRate,
    success: getGatewayPacketsPerDataRateSuccess,
    failure: getGatewayPacketsPerDataRateFailure,
  },
] = createRequestActions(
  'GET_GATEWAY_PACKETS_PER_DATA_RATE',
  (gatewayId, from, to, bucketInterval) => ({
    gatewayId,
    from,
    to,
    bucketInterval,
  }),
)

export const [
  {
    request: GET_GATEWAY_PACKETS_PER_CHANNEL,
    success: GET_GATEWAY_PACKETS_PER_CHANNEL_SUCCESS,
    failure: GET_GATEWAY_PACKETS_PER_CHANNEL_FAILURE,
  },
  {
    request: getGatewayPacketsPerChannel,
    success: getGatewayPacketsPerChannelSuccess,
    failure: getGatewayPacketsPerChannelFailure,
  },
] = createRequestActions(
  'GET_GATEWAY_PACKETS_PER_CHANNEL',
  (gatewayId, from, to, bucketInterval) => ({
    gatewayId,
    from,
    to,
    bucketInterval,
  }),
)

export const [
  {
    request: GET_APPLICATION_PACKETS_PER_DATA_RATE,
    success: GET_APPLICATION_PACKETS_PER_DATA_RATE_SUCCESS,
    failure: GET_APPLICATION_PACKETS_PER_DATA_RATE_FAILURE,
  },
  {
    request: getApplicationPacketsPerDataRate,
    success: getApplicationPacketsPerDataRateSuccess,
    failure: getApplicationPacketsPerDataRateFailure,
  },
] = createRequestActions(
  'GET_APPLICATION_PACKETS_PER_DATA_RATE',
  (applicationId, from, to, bucketInterval) => ({
    applicationId,
    from,
    to,
    bucketInterval,
  }),
)

export const [
  {
    request: GET_APPLICATION_PACKETS_PER_CHANNEL,
    success: GET_APPLICATION_PACKETS_PER_CHANNEL_SUCCESS,
    failure: GET_APPLICATION_PACKETS_PER_CHANNEL_FAILURE,
  },
  {
    request: getApplicationPacketsPerChannel,
    success: getApplicationPacketsPerChannelSuccess,
    failure: getApplicationPacketsPerChannelFailure,
  },
] = createRequestActions(
  'GET_APPLICATION_PACKETS_PER_CHANNEL',
  (applicationId, from, to, bucketInterval) => ({
    applicationId,
    from,
    to,
    bucketInterval,
  }),
)

export const [
  {
    request: GET_END_DEVICE_PACKETS_PER_DATA_RATE,
    success: GET_END_DEVICE_PACKETS_PER_DATA_RATE_SUCCESS,
    failure: GET_END_DEVICE_PACKETS_PER_DATA_RATE_FAILURE,
  },
  {
    request: getEndDevicePacketsPerDataRate,
    success: getEndDevicePacketsPerDataRateSuccess,
    failure: getEndDevicePacketsPerDataRateFailure,
  },
] = createRequestActions(
  'GET_END_DEVICE_PACKETS_PER_DATA_RATE',
  (applicationId, deviceId, from, to, bucketInterval) => ({
    applicationId,
    deviceId,
    from,
    to,
    bucketInterval,
  }),
)

export const [
  {
    request: GET_END_DEVICE_PACKETS_PER_CHANNEL,
    success: GET_END_DEVICE_PACKETS_PER_CHANNEL_SUCCESS,
    failure: GET_END_DEVICE_PACKETS_PER_CHANNEL_FAILURE,
  },
  {
    request: getEndDevicePacketsPerChannel,
    success: getEndDevicePacketsPerChannelSuccess,
    failure: getEndDevicePacketsPerChannelFailure,
  },
] = createRequestActions(
  'GET_END_DEVICE_PACKETS_PER_CHANNEL',
  (applicationId, deviceId, from, to, bucketInterval) => ({
    applicationId,
    deviceId,
    from,
    to,
    bucketInterval,
  }),
)
