// Copyright © 2022 The Things Industries B.V.

import tts from '@console/api/tts'
import smApi from '@console/api/subscription-management.tti'

import createRequestLogic from '@ttn-lw/lib/store/logics/create-request-logic'
import { selectOwnTenantId } from '@ttn-lw/lib/selectors/env'
import { httpStatusCode } from '@ttn-lw/lib/errors/utils'

import * as subscriptionManagement from '@console/store/actions/subscription-management.tti'
import * as tenant from '@console/store/actions/tenant.tti'

const validate = ({ action }, allow, reject) => {
  if (!selectOwnTenantId()) {
    return reject()
  }

  allow(action)
}

const getOwnTenantLogic = createRequestLogic({
  validate,
  type: tenant.GET_OWN_TENANT,
  process: async ({ action }, dispatch) => {
    const {
      meta: { selector, options },
    } = action

    const tenantId = selectOwnTenantId()
    const ownTenant = await tts.Tenants.getById(tenantId, selector)
    const priceId = ownTenant.billing?.stripe?.plan_id

    if (options?.withSubscriptionInfo && priceId) {
      let response
      try {
        response = await smApi.subscriptionInfo(priceId)
        dispatch(subscriptionManagement.getSubscriptionInfoSuccess(response.data))
      } catch (error) {
        if (httpStatusCode(error) === 404) {
          if ('symbol' in error.response.data) {
            dispatch(subscriptionManagement.getSubscriptionInfoSuccess(error.response.data))
          } else {
            dispatch(subscriptionManagement.getSubscriptionInfoFailure())
          }
        } else {
          dispatch(subscriptionManagement.getSubscriptionInfoFailure())
        }
      }
    }

    return ownTenant
  },
})

const updateOwnTenantLogic = createRequestLogic({
  validate,
  type: tenant.UPDATE_OWN_TENANT,
  process: ({ action }) => {
    const {
      payload: { patch },
    } = action

    const tenantId = selectOwnTenantId()

    return tts.Tenants.updateById(tenantId, patch)
  },
})

const getRegistryTotalsLogic = createRequestLogic({
  type: tenant.GET_REGISTRY_TOTALS,
  process: async ({ action }) => {
    const {
      meta: { selector },
    } = action

    const tenantId = selectOwnTenantId() ?? 'default'

    return await tts.Tenants.getRegistryTotals(tenantId, selector)
  },
})

export default [getOwnTenantLogic, updateOwnTenantLogic, getRegistryTotalsLogic]
