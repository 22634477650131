// Copyright © 2024 The Things Industries B.V.

import createRequestActions from '@ttn-lw/lib/store/actions/create-request-actions'

// News
export const NEWS_BASE = 'NEWS'

export const [
  { request: GET_NEWS_LIST, success: GET_NEWS_LIST_SUCCESS, failure: GET_NEWS_LIST_FAILURE },
  { request: getNewsList, success: getNewsListSuccess, failure: getNewsListFailure },
] = createRequestActions(`GET_${NEWS_BASE}_LIST`)
