// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'
import { useSelector } from 'react-redux'

import LogoComponent from '@ttn-lw/components/logo'

import {
  selectApplicationSiteName,
  selectTTSStandardLogo,
  selectTTSPlusLogo,
  selectTTSDiscoveryLogo,
  selectTTSCloudLogo,
  selectTTSLogo,
  selectPluginTTSCloudEnabled,
  selectTTSCloudMiniLogo,
  selectTTSMiniLogo,
  selectPluginTTSSandboxEnabled,
  selectTTSSandboxLogo,
} from '@ttn-lw/lib/selectors/env'

import { selectSubscriptionProduct } from '@console/store/selectors/subscription-management.tti'

const Logo = props => {
  // TTI only.
  const subscriptionInfo = useSelector(selectSubscriptionProduct)
  const cloudTier = subscriptionInfo?.product?.symbol
  const ttsSandBoxPluginEnabled = selectPluginTTSSandboxEnabled()
  const ttsCloudPluginEnabled = selectPluginTTSCloudEnabled()
  const ttsTierLogo = ttsCloudPluginEnabled
    ? cloudTier === 'STANDARD'
      ? selectTTSStandardLogo()
      : cloudTier === 'PLUS'
        ? selectTTSPlusLogo()
        : cloudTier === 'DISCOVERY'
          ? selectTTSDiscoveryLogo()
          : selectTTSCloudLogo()
    : ttsSandBoxPluginEnabled
      ? selectTTSSandboxLogo()
      : selectTTSLogo()

  const ttsTierMiniLogo = ttsCloudPluginEnabled ? selectTTSCloudMiniLogo() : selectTTSMiniLogo()
  // End TTI.

  const logo = {
    src: ttsTierLogo,
    alt: `${selectApplicationSiteName()} Logo`,
  }

  const miniLogo = {
    src: ttsTierMiniLogo,
    alt: `${selectApplicationSiteName()} Logo`,
  }

  return <LogoComponent logo={logo} miniLogo={miniLogo} {...props} />
}

export default Logo
