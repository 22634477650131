// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { useSelector } from 'react-redux'

import { selectPluginTTSCloud } from '@ttn-lw/lib/selectors/env'

import { selectOwnTenant } from '@console/store/selectors/tenant.tti'

const smUrl = selectPluginTTSCloud().subscription_management_url
const plansPageUrl = selectPluginTTSCloud().plans_page_url

const useUpgradeUrl = () => {
  let upgradeUrl = plansPageUrl

  const ownTenant = useSelector(selectOwnTenant)
  const tenantStripe = ownTenant?.billing?.stripe
  const subscriptionId = tenantStripe?.subscription_id
  if (subscriptionId && Boolean(smUrl)) {
    upgradeUrl = `${smUrl}/dashboard/subscriptions/${subscriptionId}/upgrade-cloud`
  }

  return { upgradeUrl }
}

export default useUpgradeUrl
